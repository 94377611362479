.widget.box .widget-footer {
    padding: 2rem 2.25rem;
    background-color: #ffffff;
    /* border-top: 1px solid #EBEDF3; */
}
.annualTitles {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: black
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
