.item-content {
    background-color: #fff;
    padding: 13px 18px;
    box-shadow: 0px 0px 4px 1px rgba(126, 142, 177, 0.12);
    -webkit-box-shadow: 0px 0px 8px 2px rgba(126, 142, 177, 0.22);
    border-radius: 6px;
    /* cursor: pointer; */
    overflow: hidden;
    position: relative;
}

.user-profile {
    text-align: center;
    margin-top: 20px;
}

.user-meta-info p {
    margin-top: 0;
    margin-bottom: 0.625rem;
    color: #515365;
}

.featuredwidget {
    position: relative;
    padding: 10px 10px 10px 10px;
    border-radius: 8px;
    border: none;
    background: #fff;
    box-shadow: 0 4px 6px 0 rgba(85, 85, 85, 0.08), 0 1px 20px 0 rgba(0, 0, 0, 0.07), 0px 1px 11px 0px rgba(0, 0, 0, 0.07);
}

.featured {

    padding: 20px 35px 20px 10px;
}

.paraSpotlight {
    width: 250px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.paraSpotlight1 {
    width: 250px;
    /* height: 20%; */
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

@media (max-width: 767px) {
    .featured {
        padding: 0px 0px 0px 0px;
    }
    .user-profile {
        text-align: center;
        margin-top: 0px;
    }
    .featuredCards{
       
    }
}