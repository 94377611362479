.br-6 {
    border-radius: 6px!important;
}

.coming {
    margin: 0;
    padding: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    color: black;
    font-family: 'Poppins', sans-serif; 
    font-size: 36px 
  }
  
  .annualTitles {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: black;
}