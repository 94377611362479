:where(.css-dev-only-do-not-override-htwhyh).ant-btn-default {
    background-color: #ffffff;
    /* border-color: #d9d9d9; */
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
    width: 200px !important;
    height: 42px !important;
    border: 1px solid #d2d9f8 !important;
}

.annualTitles {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 0px;
    margin-bottom: 0;
    color: black;
}

@media (max-width: 767px) {
    .upload-list-inline .ant-upload-list-item {
        float: left;
        width: 200px;
        margin-inline-end: 8px;
    }

    .ant-upload-rtl.upload-list-inline .ant-upload-list-item {
        float: right;
    }
}