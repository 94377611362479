.ant-select-selector {
    width: 100%;
    height: 40px !important;
    padding: 0 11px !important;
    position: relative;
    background-color: #f7f6f6 !important;
    border: 1px solid #d9d9d9 !important;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
    border-radius: 50px !important;
}
span.ant-select-selection-placeholder {
    padding-inline-end: 18px !important;
    color: rgb(41, 40, 40) !important;
    font-family: 'Poppins', sans-serif !important;
}
.ant-select-dropdown{
    z-index: 1 !important;
}
.btn-primaryss {
    color: #fff !important;
    background-color: #1D4AEB !important;
    border-color: #1D4AEB;
    width: 100px !important;
}
.ant-select-item-empty {
    padding: 20px !important;
    text-align: center !important;
    color: grey !important;
}